var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-space-between":""}},[(_vm.editUrl && (!_vm.item.ReadOnly || _vm.editItemAdmin))?_c('v-text-field',{staticClass:"mr-8",attrs:{"label":_vm.$t('Website URL'),"rules":_vm.$validation.getValidation('notEmptyOrWrongURLRule'),"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.item.Value),callback:function ($$v) {_vm.$set(_vm.item, "Value", $$v)},expression:"item.Value"}}):(_vm.item.Value)?_c('a',{attrs:{"href":_vm.item.Value,"target":"_blank"}},[_c('v-layout',{attrs:{"fill-height":"","align-center":"","justify-start":""}},[(!_vm.$store.getters.editMode)?_c('v-img',{staticClass:"mr-2",attrs:{"contain":"","max-height":"16","max-width":"16","src":_vm.getFavicon(_vm.item)}}):_vm._e(),_c('p',{staticClass:"mb-0 text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.getDomainUrl(_vm.item.Value))+" ")])],1)],1):_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("No URL added yet"))+" ")]),((!_vm.item.ReadOnly && !_vm.needsDisable) || _vm.editItemAdmin)?_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"label":_vm.$t('Edit'),"hide-details":"auto"},model:{value:(_vm.editUrl),callback:function ($$v) {_vm.editUrl=$$v},expression:"editUrl"}}):_vm._e()],1),(_vm.$store.getters.editMode)?_c('hr',{staticClass:"mt-5"}):_vm._e(),(_vm.$store.getters.editMode)?_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":_vm.item.colWidth < 7
          ? _vm.item.colWidth < 5
            ? 12
            : 4
          : _vm.item.colWidth < 10
          ? 4
          : 2}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{staticClass:"mt-4",attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","dense":"","outlined":"","label":_vm.$t('Column width')},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Remove frame')},model:{value:(_vm.item.RemoveCard),callback:function ($$v) {_vm.$set(_vm.item, "RemoveCard", $$v)},expression:"item.RemoveCard"}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }